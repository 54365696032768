/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'

type Props = {}

const CardsWidgetServices: FC<Props> = ({}) => {

  return (
    <div className='col-xl-4'>
      <div className='card card-xl-stretch my-3'>
        <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
          <div className='d-flex flex-column content-justify-center flex-row-fluid h-100'>
            <Link className={clsx('card-title fw-bold fs-5 mb-3 d-block', {active: false})} to={'/services'} >Услуги</Link>
            <div className='d-flex fw-semibold align-items-center my-5'>
              <div className='text-gray-500 flex-grow-1 me-4'>Без абонентской платы</div>
              <div className='fw-bolder text-gray-700 text-xxl-end'>9</div>
            </div>
            <div className='d-flex fw-semibold align-items-center my-5'>
              <div className='text-gray-500 flex-grow-1 me-4'>С абонентской платой</div>
              <div className='fw-bolder text-gray-700 text-xxl-end'>1</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {CardsWidgetServices}
