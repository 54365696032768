import {FC, createContext, useContext} from 'react'
import {
  WithChildren,
} from '../../../../../_metronic/helpers'
import {useQueryResponse, useQueryResponseData} from './QueryResponseProvider'

export type DataViewContextProps = {}

export const initialDataView: DataViewContextProps = {}

const DataViewContext = createContext<DataViewContextProps>(initialDataView)

const DataViewProvider: FC<WithChildren> = ({children}) => {
  const {isLoading} = useQueryResponse()
  const data = useQueryResponseData()

  return (
    <DataViewContext.Provider
      value={{}}
    >
      {children}
    </DataViewContext.Provider>
  )
}

const useDataView = () => useContext(DataViewContext)

export {DataViewProvider, useDataView}
