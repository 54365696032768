import ApiClient from "../../../../../app/components/ApiClient";
import {GET_GROUP} from "../../../../../app/components/_queries";
import { GroupsQueryResponse } from "./_models"

export const getGroup = (): Promise<GroupsQueryResponse> => {
    return ApiClient.client.query({query: GET_GROUP}).then((result) => {
      return {
        data: result.data.me.account.number.groups
      }
    });
  }