import { ApolloClient, InMemoryCache, DefaultOptions } from '@apollo/client'
import configurator from './client-configs/Configurator'

configurator.getConfigs().then(function (configs) {
  ApiClient.setClient(new ApolloClient({
    uri: configs['apiUrl'] + '/graphql',
    cache: new InMemoryCache(),
    headers: {
      'X-Auth-Client-Key': configs['apiAuthKey']
    },
    defaultOptions: defaultOptions,
    credentials: 'include',
  }))
})

const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
let ApiClient = {
  client: new ApolloClient({
    uri: '',
    cache: new InMemoryCache(),
    headers: {
      'X-Auth-Client-Key': ''
    },
    defaultOptions: defaultOptions,
    credentials: 'include',
  }),
  setClient: function (client: any) {
    this.client = client
  }
}

export default ApiClient
