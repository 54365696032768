/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {CardsWidgetUser} from './widgets/CardsWidgetUser'
import {CardsWidgetTasks} from './widgets/CardsWidgetTasks'
import {CardsWidgetStatus} from './widgets/CardsWidgetStatus'
import {CardsWidgetServices} from './widgets/CardsWidgetServices'
import {CardsWidgetDetails} from './widgets/CardsWidgetDetails'
import {CardsWidgetOperationsWrapper} from './widgets/CardsWidgetOperations'
import {CardsWidgetRemainsWrapper} from './widgets/CardsWidgetRemains'
import {CardsWidgetBalance} from './widgets/CardsWidgetBalance'

const DashboardPage: FC = () => {

  return (<div className='row row g-5 g-xl-8'>
        <CardsWidgetUser/>
        <CardsWidgetStatus />
        <CardsWidgetRemainsWrapper />
        <CardsWidgetOperationsWrapper />
        {/* <CardsWidgetBalance /> */}
        {/* <CardsWidgetTasks /> */}
        {/* <CardsWidgetDetails /> */}
        <CardsWidgetServices />
    </div>
  )
}

const DashboardWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Главная</PageTitle>
          <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
