import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {useLayout} from '../../core'
import {Footer} from './Footer'
import configurator from '../../../../app/components/client-configs/Configurator'

async function getFooterContent () {
  return await configurator.getFooter()
}

const FooterWrapper = () => {
  const [footerContent, setFooterContent] = useState('')

  useEffect(() => {
    const fetchContent = async () => {
      const data = await getFooterContent()
      setFooterContent(data)
   }
 
   fetchContent()
  }, [])

  const {config} = useLayout()
  if (!config.app?.footer?.display) {
    return null
  }

  return (
    <div className='app-footer' id='kt_app_footer'>
      {config.app.footer.containerClass ? (
        <div className={clsx('app-container', config.app.footer.containerClass)}>
          <div dangerouslySetInnerHTML={{__html: footerContent}} />
        </div>
      ) : (
        <Footer />
      )}
    </div>
  )
}

export {FooterWrapper}
