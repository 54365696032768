import {useMemo, useState, useEffect} from 'react'
import clsx from 'clsx'
import { useAuth } from '../../../../../app/modules/auth'
import {DataViewProvider} from './DataViewProvider'
import { QueryRequestProvider } from './QueryRequestProvider'
import { QueryResponseProvider, useQueryResponseData } from './QueryResponseProvider'
import { Group, GroupNumber } from './_models'
import configurator, { strObj } from '../../../../../app/components/client-configs/Configurator'

type OneNumberProps = {
    number: GroupNumber
    currentNumber: string
}

async function getConfig () {
  return await configurator.getConfigs()
}

const OneNumber = ({number, currentNumber}: OneNumberProps) => {

    const {saveAuth} = useAuth()

    function changePhone (msisdn: string, event: any) {
        event.preventDefault()
        saveAuth({msisdn: msisdn})
        window.location.reload()
    }

    return (
        <div className='menu-item px-3 my-0'>
            <a
                href='#'
                onClick={(event) => changePhone(number.msisdn, event)}
                className={clsx('menu-link px-3 py-2', {active: currentNumber === number?.msisdn})}
            >
                <span className='menu-title'>{number?.msisdn ? number?.msisdn.replace( /(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($1) $2-$3-$4' ) : ''}</span>
            </a>
        </div>
    )
}

type RowNumbersProps = {
    group: Group
    currentNumber: string
}

const RowNumbers = ({group, currentNumber}: RowNumbersProps) => {
    if (group.numbers.length > 0) {
        return (
            <>
                { group.numbers.map((row: GroupNumber, index) => {
                    return <OneNumber number={row} currentNumber={currentNumber} key={`group-rows-${index}`}/>
                }) }
            </>
        )
    }
    return (<></>)
    
}

const GroupList = () => {
    const data = useQueryResponseData()
    const { auth } = useAuth()

    let groups = useMemo(() => data, [data])

    return (
      <>
          <a
            href='#'
            className={'menu-link'}
            data-kt-menu-trigger={"{default: 'click', lg: 'hover'}"}
            data-kt-menu-attach='parent'
            data-kt-menu-placement={'bottom-end'}
        >
            <div className={'btn btn-custom '}>Выбранный номер: </div><ins id="ins-number">{ auth.msisdn.replace( /(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($1) $2-$3-$4' )}</ins>
        </a>
        {groups.length > 0 ? (
            <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
                data-kt-menu='true'
            >
            {
                groups.map((group: Group, i) => {
                    return <RowNumbers group={group} currentNumber={auth.msisdn} key={`group-rows-${i}`}/>
                })
            }
            </div>
        ) : ('')}    
      </>
    )
  }
  
const GroupEmptyList = () => {

    const { auth } = useAuth()

	const aStyle = { cursor: 'default' };
	const dStyle = { cursor: 'default', color: '#181C32' };
	const insStyle = { cursor: 'default', textDecoration: 'none' };

    return (
      <>
          <a
            href='#'
			style={aStyle}
            className={'menu-link'}
            data-kt-menu-trigger={"{default: 'click', lg: 'hover'}"}
            data-kt-menu-attach='parent'
            data-kt-menu-placement={'bottom-end'}
        >
            <div className={'btn btn-custom '} style={dStyle}>Выбранный номер: </div><ins style={insStyle}>{ auth.msisdn.replace( /(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($1) $2-$3-$4' )}</ins>
        </a>
      </>
    )
  }  


const GroupSelectWrapper = () => {

  const [config, setConfig] = useState<strObj>({});

  useEffect(() => {
    const fetchContent = async () => {
      const data = await getConfig()
      setConfig(data)
	  //console.log('config data', data, config);
   }
 
   fetchContent()
  }, [])
  

	return config['enableNumbers'] ? (
		<QueryRequestProvider>
			<QueryResponseProvider>
				<DataViewProvider>
					<GroupList />
				</DataViewProvider>
			</QueryResponseProvider>
		</QueryRequestProvider>
	) : (
		<QueryRequestProvider>
			<QueryResponseProvider>
				<DataViewProvider>
					<GroupEmptyList />				
				</DataViewProvider>
			</QueryResponseProvider>
		</QueryRequestProvider>	
	);
};
	

export { GroupSelectWrapper }