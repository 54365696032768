import {useState, FC} from 'react'
import * as Yup from 'yup'
import "yup-phone";
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import { verificationSubmit } from '../../../../components/user/password/_requests'
import { Verification } from './_models';
import { generateCorrelationId } from '../../../../components/_helpers'

type Props = {
  updateStep: any
  correlationId: any
  verification: Verification
}

const initialValues = {
  code: '',
}

const inputSchema = Yup.object().shape({
  code: Yup.string().required('Код обязателен')
})
export const StepCode: FC<Props> = ({updateStep, correlationId, verification}) => {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [error, setError] = useState('')
  const formik = useFormik({
    initialValues,
    validationSchema: inputSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      verificationSubmit(values.code, verification.id, correlationId, generateCorrelationId())
        .then(({data: {verificationSubmit}, errors}) => {
          if (verificationSubmit.result.isSuccess) {
            updateStep(3)
            setHasErrors(false)
            setLoading(false)
          }
        })
        .catch(({message}) => {
          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
          setStatus(message)
          setError(message)
        })
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>Забыли пароль ?</h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          Введите полученный код для восстановления пароля.
        </div>
      </div>

      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            {error}
          </div>
        </div>
      )}

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Код</label>
        <input
            {...formik.getFieldProps('code')}
            placeholder='Код'
            type='text'
            autoComplete='off'
            className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.code && formik.errors.code},
                {
                  'is-valid': formik.touched.code && !formik.errors.code,
                }
            )}
        />
        {formik.touched.code && formik.errors.code && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.code}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>Отправить</span>
          {loading && (
            <span className='indicator-progress'>
              Пожалуйста подождите...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Отмена
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
