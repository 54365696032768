/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useAuth} from "../../../modules/auth";
import { priceWithSpaces } from '../../../components/_helpers';

const CardsWidgetStatus: FC = () => {

  const { currentUser } = useAuth()

  let balance = ''
  if (currentUser?.billingNumber.balance) {
    balance = priceWithSpaces(currentUser.billingNumber.balance)
  }

  return (
    <div className='col-xl-4'>
      <div className='card card-xl-stretch my-3'>
        <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
          <div className='d-flex flex-column content-justify-center flex-row-fluid h-100'>
            <Link className={clsx('card-title fw-bold fs-5 mb-3 d-block', {active: false})} to={'/account'} >Статус</Link>
            <div className='d-flex fw-semibold align-items-center my-4'>
              <div className='text-gray-500 flex-grow-1 me-4'>Баланс</div>
              <div className=' fw-bolder text-gray-700 text-xxl-end'>{balance}</div>
            </div>
            <div className='d-flex fw-semibold align-items-center my-4'>
              <div className='text-gray-500 flex-grow-1 me-4'>Статус</div>
              <div className='fw-bolder text-gray-700 text-xxl-end'>
                {
                  currentUser?.billingNumber.isActive ? (
                    <span className="badge py-3 px-4 fs-7 badge-light-success">Активен</span>
                  ) : (
                    <span className="badge py-3 px-4 fs-7 badge-light-danger">Заблокирован</span>
                  )
                }
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {CardsWidgetStatus}
