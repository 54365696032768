/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from 'react'
import {SidebarMenuItem} from './SidebarMenuItem'
import configurator, { strObj } from '../../../../../app/components/client-configs/Configurator'

async function getConfig () {
  return await configurator.getConfigs()
}

const SidebarMenuMain = () => {
  const [config, setConfig] = useState<strObj>({});

  useEffect(() => {
    const fetchContent = async () => {
      const data = await getConfig()
      setConfig(data)
   }
 
   fetchContent()
  }, [])

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title='Главная'
        fontIcon='bi-app-indicator'
      />
        {config['enableNumbers'] && (
          <SidebarMenuItem to='/group' title='Дочерние номера' hasBullet={false} />
        )}
        {/* <SidebarMenuItem to='/tasks' title='Заявки' hasBullet={false} /> */}
        <SidebarMenuItem to='/services' title='Услуги' hasBullet={false} />
        {/* <SidebarMenuItem to='/tariff' title='Тариф' hasBullet={false} /> */}
        <SidebarMenuItem to='/operations' title='Расходы за последний месяц' hasBullet={false} />
        <SidebarMenuItem to='/details' title='Детализация' hasBullet={false} />
        <SidebarMenuItem to='/remains' title='Остатки пакетов' hasBullet={false} />
    </>
  )
}

export {SidebarMenuMain}
