import axios from 'axios'
import {
  AuthModel,
  UserModel
} from './_models'
import ApiClient from "../../../components/ApiClient";
import {GET_USER_QUERY} from "../../../components/_queries";
import configurator from '../../../components/client-configs/Configurator';
let loginUrl = ''
let authClientKey = ''
configurator.getConfigs().then(function (configs) {
  loginUrl = `${configs['apiUrl']}/auth/v1/login/password`
  authClientKey = configs['apiAuthKey']
})

export async function login(phone: string, password: string) {
  let data = `username=${phone}&password=${password}`
  const result = await axios.post<AuthModel>(loginUrl, data, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
      'X-Auth-Client-Key': authClientKey,
    }
  })

  return result
}

function sleep(milliseconds: number) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

async function queryUser(msisdn: string) {
  return ApiClient.client.query({query: GET_USER_QUERY, variables: {msisdn: msisdn}}).then((result) => {
    return {
      data: <UserModel>result.data.me.account
    }
  });
}

export async function getUser(msisdn: string):Promise<any> {
  if (authClientKey === '') {
    return sleep(500).then(() => { return getUser(msisdn) })
  } else {
    return queryUser(msisdn)
  }
}