/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useMemo} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {ListViewProvider} from './../../../modules/remains/remains-list/core/ListViewProvider'
import {QueryRequestProvider} from './../../../modules/remains/remains-list/core/QueryRequestProvider'
import {QueryResponseProvider} from './../../../modules/remains/remains-list/core/QueryResponseProvider'
import {Remain} from './../../../modules/remains/remains-list/core/_models'

import {useQueryResponseData} from './../../../modules/remains/remains-list/core/QueryResponseProvider'

type Props = {}

const CardsWidgetRemains: FC<Props> = ({}) => {

  const remains = useQueryResponseData()
  const data = useMemo(() => remains, [remains])

  const names: {[index: string]:any} = {
    'MINUTES': 'Минуты',
    'SMS': 'СМС',
    'MB': 'Мегабайт'
  }

  let rowClass = 'my-5'
  if (data.length === 3) {
    rowClass = 'my-2'
  } else if (data.length === 1) {
    rowClass = 'my-12'
  }

  return (
    <div className='col-xl-4'>
      <div className='card card-xl-stretch my-3'>
        <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
          <div className='d-flex flex-column content-justify-center flex-row-fluid h-100'>
            <Link className={clsx('card-title fw-bold fs-5 mb-3 d-block', {active: false})} to={'/remains'} >Остатки по пакетам</Link>
            {data.length > 0 ? (
              data.map((remain: Remain, i) => {
                return (
                  <div key={i} className={clsx('d-flex fw-semibold align-items-center', rowClass)}>
                    <div className='text-gray-500 flex-grow-1 me-4'>{remain.measure ? names[remain.measure] : ''}</div>
                    <div className=' fw-bolder text-gray-700 text-xxl-end'>{remain.balance}/{remain.size}</div>
                  </div>
                )
              })
            ) : (
              <div className={clsx('d-flex fw-semibold align-items-center my-12')}>
                  <div className='text-gray-500 flex-grow-1 me-4'> </div>
                  <div className=' fw-bolder text-gray-700 text-xxl-end'> </div>
                </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const CardsWidgetRemainsWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CardsWidgetRemains />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CardsWidgetRemainsWrapper}
