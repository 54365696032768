/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import "yup-phone";
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUser, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {HelpModalButton} from './HelpModalButton'
import {HelpModal} from './HelpModal'
import InputMask from 'react-input-mask'

const phoneRegExp = /\+7\s[\(][0-9]{3}[\)]\s[0-9]{3}[-][0-9]{2}[-][0-9]{2}$/

const loginSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(phoneRegExp, 'Номер телефона указан не верно')
    .required('Телефон обязательное поле'),
  password: Yup.string()
    .min(3, 'Минимум 3 символов')
    .max(50, 'Максимум 50 символов')
    .required('Пароль обязателен'),
})

const initialValues = {
  phone: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const [showHelp, setShowHelp] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let msisdn = values.phone.replace(/[^0-9]/g, '').substring(1)
        await login(msisdn, values.password)
        const {data: user} = await getUser(msisdn)
        setCurrentUser(user)
        saveAuth({msisdn: msisdn})
      } catch (error) {
        console.error(error)
        saveAuth({msisdn: ''})
        setStatus('Телефон или пароль не верны')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Авторизация</h1>
      </div>

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div> </div>
      )}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Телефон</label>
        <InputMask mask={'+7 (999) 999-99-99'}
            autoComplete='off'
            className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.phone && formik.errors.phone},
                {
                  'is-valid': formik.touched.phone && !formik.errors.phone,
                }
            )}
            placeholder='Телефон'
            {...formik.getFieldProps('phone')}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Пароль</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        <Link to='/auth/forgot-password' className='link-primary'>
          Забыли пароль ?
        </Link>
      </div>
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Продолжить</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Пожалуйста подождите...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        <Link to='/auth/registration' className='link-primary'>
          Зарегистрироваться
        </Link>
      </div>
      <HelpModalButton callback={setShowHelp}/>
    </form>
    {showHelp && <HelpModal callback={setShowHelp}/>}
    </>
  )
}
