import {useEffect, useState} from 'react'
import configurator, { strObj } from './app/components/client-configs/Configurator'

async function getConfig () {
  return await configurator.getConfigs()
}

const PageVariables = () => {
  const [config, setConfig] = useState<strObj>({});

  useEffect(() => {
    const fetchContent = async () => {
      const data = await getConfig()
      setConfig(data)

      let shortIconSelector = document.head.querySelector('[rel="shortcut icon"]')
      if (data.favicon && shortIconSelector) {
        shortIconSelector.setAttribute('href', data.favicon)
      }
      let title = document.head.querySelector('title')
      if (data.title && title) {
        title.text = data.title
      }
   }

   fetchContent()
  }, [])

  return <></>
}

export {PageVariables}