import {useState} from 'react'
import "yup-phone"
import { StepPhone } from './forgotPassword/StepPhone'
import { StepCode } from './forgotPassword/StepCode'
import { StepPassword } from './forgotPassword/StepPassword'
import { StepSuccess } from './forgotPassword/StepSuccess'
import { generateCorrelationId } from '../../../components/_helpers'
import { Verification, initialVerificationValues } from './forgotPassword/_models'

// 1 step - ввод номер телефона
// 2 step - ввод кода подтверждения
// 3 step - ввод нового пароля
// 4 step - пароль успешно изменен

export function ForgotPassword() {

  const [step, setStep] = useState(1)
  const [correlationId] = useState(generateCorrelationId())
  const [passwordChangeId] = useState(generateCorrelationId())
  const [verification, setVerification] = useState<Verification>(initialVerificationValues)

  return (
    <>
    {step === 1 && <StepPhone
      updateStep={setStep}
      correlationId={correlationId}
      passwordChangeId={passwordChangeId}
      setVerification={setVerification}
      />}
    {step === 2 && <StepCode
      updateStep={setStep}
      correlationId={correlationId}
      verification={verification}
      />}
    {step === 3 && <StepPassword
      updateStep={setStep}
      correlationId={correlationId}
      passwordChangeId={passwordChangeId}
      />}
      {step === 4 && <StepSuccess/>}
  </>
  )
}
