import {RemainsQueryResponse} from './_models'
import ApiClient from "../../../../components/ApiClient";
import {GET_REMAINS} from "../../../../components/_queries";

const getRemains = (msisdn: string): Promise<RemainsQueryResponse> => {
  return ApiClient.client.query({query: GET_REMAINS, variables: {msisdn: msisdn}}).then((result) => {
    return {data: result.data.me.account.billingNumber.remains.simple}
  });
}

export {getRemains}
