import clsx from 'clsx'
import {HeaderNotificationsMenu} from '../../../partials'
import {useAuth} from "../../../../app/modules/auth";
import {Link} from "react-router-dom";
import { GroupSelectWrapper } from './groups/GroupSelect';

const itemClass = 'ms-1 ms-lg-3'

const Navbar = () => {
  const {logout} = useAuth()
  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <GroupSelectWrapper />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div className='menu-item px-5 my-1'>
          <Link to='/account' className='menu-link px-5'>
            Профиль
          </Link>
        </div>

        <div className='menu-item px-5'>
          <a onClick={logout} className='menu-link px-5'>
            Выйти
          </a>
        </div>
      </div>
    </div>
  )
}

export {Navbar}
