import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'

import configurator, { strObj } from '../../../../app/components/client-configs/Configurator'

async function getConfig () {
  return await configurator.getConfigs()
}

const SidebarLogo = () => {
  const {config} = useLayout()

  const [logoPath, setLogoPath] = useState(toAbsoluteUrl('/media/logos/eko-logo-new.png'));
  const [logoSizeMin, setLogoSizeMin] = useState({});
  const [logoSize, setLogoSize] = useState({});

  const [logoDivPaddings, setLogoDivPaddings] = useState({
    paddingTop: '4px',
	paddingBottom: '0',  
	minHeight: '0',
  });

  useEffect(() => {
    const fetchContent = async () => {
      const data = await getConfig()
      if (data.logo) {
        setLogoPath(data.logo);
        setLogoSize(data.logoSize);
        setLogoSizeMin(data.logoSizeMin);

		//@ts-ignore
		const height = parseInt(data.logoSize.height);
		setLogoDivPaddings({
			paddingTop: '4px',
			paddingBottom: '0',  			
			minHeight: (height + 41) + 'px',
		});
      }
   }

   fetchContent()
  }, [])

  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default

  return (
    <div className='app-sidebar-logo px-6' id='kt_app_sidebar_logo' style={logoDivPaddings}>
      <Link to='/dashboard'>
        {config.layoutType === 'dark-sidebar' ? (
          <img
            alt='Logo'
            src={logoPath}
            className='app-sidebar-logo-default'
            style={logoSize}
          />
        ) : (
          <>
            <img
              alt='Logo'
              src={logoPath}
              className='app-sidebar-logo-default theme-light-show'
              style={logoSize}
            />
            <img
              alt='Logo'
              src={logoPath}
              className='app-sidebar-logo-default theme-dark-show'
              style={logoSize}
            />
          </>
        )}
      </Link>

      {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          id='kt_app_sidebar_toggle'
          className={clsx(
            'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate',
            {active: appSidebarDefaultMinimizeDefault}
          )}
          data-kt-toggle='true'
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target='body'
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr079.svg' className='svg-icon-2 rotate-180' />
        </div>
      )}
    </div>
  )
}

export {SidebarLogo}
