import {OperationsQueryResponse} from './_models'
import ApiClient from "../../../../components/ApiClient";
import {GET_EXPENSES} from "../../../../components/_queries";

const getExpenses = (year: number, month: number, msisdn: string): Promise<OperationsQueryResponse> => {
  return ApiClient.client.query({
    query: GET_EXPENSES,
    variables: {year: year, month: month, msisdn: msisdn}
  }).then((result) => {
    return {data: result.data.me.account.billingNumber.expenses.month?.transactionList?.nodes}
  });
}

export {getExpenses}
