/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, FC} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import { registrationCreate, registrationVerify } from '../../../../components/_requests'
import { generateCorrelationId } from '../../../../components/_helpers'
import InputMask from 'react-input-mask'
import Inputmask from 'inputmask';

const phoneRegExp = /\+7\s[\(][0-9]{3}[\)]\s[0-9]{3}[-][0-9]{2}[-][0-9]{2}$/

//const emailRegExp = /^\S*$/

type Props = {
  updateStep: any
  correlationId: string
  setVerification: any
  registrationId: string
  msisdn: string
  setMsisdn: any  
}

const initialValues = {
  email: '',
  contactName: '',
  contactPhone: '',
}

const stepSchema = Yup.object().shape({
  email: Yup.string()
    //.matches(emailRegExp, 'Укажите корректный email')
	.email('Укажите корректный email')
    .required('Email обязателен'),
  contactName: Yup.string()
    .required('Контактное имя обязательно'),
  contactPhone: Yup.string()
    .matches(phoneRegExp, 'Контактный номер телефона указан не верно')
	.required('Контактный номер телефона обязателен'),
})

  export const StepTypePhone: FC<Props> = ({updateStep, correlationId, setVerification, registrationId, msisdn, setMsisdn}) => {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: stepSchema,
		
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      // updateStep(4)
      try {
        //setMsisdn(values.contactPhone.replace(/[^0-9]/g, '').substring(1))
        registrationCreate(
          msisdn,
          correlationId,
          generateCorrelationId(),
          registrationId,
          '',
          values.email,
          values.contactName,
          values.contactPhone.replace(/[^0-9]/g, '').substring(1)
          )
        .then(({data: {registrationCreate}}) => {
          registrationVerify(registrationId, correlationId, generateCorrelationId())
          .then(({data: {registrationVerify}}) => {
            setVerification(registrationVerify.verification)
            updateStep(4)
            setHasErrors(false)
            setLoading(false)
          })
          setHasErrors(false)
          setLoading(false)
        })
        .catch(({message}) => {
          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
          setStatus(message)
        })
      } catch (error) {
        console.error(error)
        setStatus('Возникла непредвиденная ошибка. Обратитесь, пожалуйста, к оператору службы поддержки.')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  
  useEffect(() => {
    const emailInput = document.getElementById('email') as HTMLInputElement;
    const emailMask = new Inputmask('email', {
		tabThrough: true,
	});
	emailMask.mask(emailInput);
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_registration_first_form'
      onSubmit={formik.handleSubmit}
    >
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='text'
		  id='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.email && formik.errors.email,
            },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Контактное имя</label>
        <input
          placeholder='Контактное имя'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('contactName')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.contactName && formik.errors.contactName,
            },
            {
              'is-valid': formik.touched.contactName && !formik.errors.contactName,
            }
          )}
        />
        {formik.touched.contactName && formik.errors.contactName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.contactName}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Контактный номер телефона</label>
        <InputMask mask={'+7 (999) 999-99-99'}
          placeholder='Контактный номер телефона'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('contactPhone')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.contactPhone && formik.errors.contactPhone,
            },
            {
              'is-valid': formik.touched.contactPhone && !formik.errors.contactPhone,
            }
          )}
        />		
        {formik.touched.contactPhone && formik.errors.contactPhone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.contactPhone}</span>
            </div>
          </div>
        )}
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Отправить</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Пожалуйста подождите...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Авторизация
          </button>
        </Link>
      </div>
    </form>
  )
}
