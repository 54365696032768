/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useMemo} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {ListViewProvider} from './../../../modules/operations/operations-list/core/ListViewProvider'
import {QueryRequestProvider} from './../../../modules/operations/operations-list/core/QueryRequestProvider'
import {QueryResponseProvider} from './../../../modules/operations/operations-list/core/QueryResponseProvider'
import {useQueryResponseData} from './../../../modules/operations/operations-list/core/QueryResponseProvider'
import { Operation } from '../../../modules/operations/operations-list/core/_models'
import { priceWithSpaces } from '../../../components/_helpers'
import { array } from 'yup/lib/locale'

type Props = {}

const CardsWidgetOperations: FC<Props> = ({}) => {

  const expenses = useQueryResponseData()
  const data = useMemo(() => expenses, [expenses])
  return (
    <div className='col-xl-4'>
      <div className='card card-xl-stretch my-3'>
        <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
          <div className='d-flex flex-column content-justify-center flex-row-fluid h-100'>
            <Link className={clsx('card-title fw-bold fs-5 mb-3 d-block', {active: false})} to={'/operations'} >Расходы за последний месяц</Link>
            {data.length > 0 ? (
              data.splice(0, 3).map((operation: Operation, i) => {
                return (
                  <div key={i} className='d-flex fw-semibold align-items-center my-2'>
                    <div className='text-gray-500 flex-grow-1 me-4'>{operation.name}</div>
                    <div className=' fw-bolder text-gray-700 text-xxl-end'>{priceWithSpaces(operation.amount)}</div>
                  </div>
                )
              })
            ) : (
              <>
                <div className='d-flex fw-semibold align-items-center my-2'>
                  <div className='text-gray-500 flex-grow-1 me-4'>&nbsp;</div>
                  <div className=' fw-bolder text-gray-700 text-xxl-end'>&nbsp;</div>
                </div>
                <div className='d-flex fw-semibold align-items-center my-2'>
                  <div className='text-gray-500 flex-grow-1 me-4'>&nbsp;</div>
                  <div className=' fw-bolder text-gray-700 text-xxl-end'>&nbsp;</div>
                </div>
                <div className='d-flex fw-semibold align-items-center my-2'>
                  <div className='text-gray-500 flex-grow-1 me-4'>&nbsp;</div>
                  <div className=' fw-bolder text-gray-700 text-xxl-end'>&nbsp;</div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const CardsWidgetOperationsWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CardsWidgetOperations />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CardsWidgetOperationsWrapper}
