import {lazy, FC, Suspense, useEffect, useState} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import configurator, { strObj } from '../components/client-configs/Configurator'

async function getConfig () {
  return await configurator.getConfigs()
}

const PrivateRoutes = () => {

  const [config, setConfig] = useState<strObj>({});

  const GroupPage = lazy(() => import('../modules/group/GroupPage'))
  const TasksPage = lazy(() => import('../modules/tasks/TasksPage'))
  const ServicesPage = lazy(() => import('../modules/services/ServicesPage'))
  //const TariffPage = lazy(() => import('../modules/tariff/TariffPage'))
  const DetailsPage = lazy(() => import('../modules/details/DetailsPage'))
  const OperationsPage = lazy(() => import('../modules/operations/OperationsPage'))
  const RemainsPage = lazy(() => import('../modules/remains/RemainsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

  useEffect(() => {
    const fetchContent = async () => {
      const data = await getConfig()
      setConfig(data)
   }
 
   fetchContent()
  }, [])

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
          {config['enableNumbers'] && (
            <Route
              path='group'
              element={
                  <SuspensedView>
                      <GroupPage />
                  </SuspensedView>
              }
            />
          )}
          {/* <Route
            path='tasks'
            element={
                <SuspensedView>
                    <TasksPage />
                </SuspensedView>
            }
          /> */}
          <Route
            path='services'
            element={
                <SuspensedView>
                    <ServicesPage />
                </SuspensedView>
            }
          />
          {/* <Route
            path='tariff'
            element={
                <SuspensedView>
                    <TariffPage />
                </SuspensedView>
            }
          /> */}
          <Route
            path='operations'
            element={
                <SuspensedView>
                    <OperationsPage />
                </SuspensedView>
            }
          />
          <Route
            path='details'
            element={
                <SuspensedView>
                    <DetailsPage />
                </SuspensedView>
            }
          />
          <Route
            path='remains'
            element={
                <SuspensedView>
                    <RemainsPage />
                </SuspensedView>
            }
          />
        {/* Lazy Modules */}
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
