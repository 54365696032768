/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  QUERIES,
  WithChildren,
} from '../../../../../_metronic/helpers'
import {getGroup} from './_requests'
import {Group} from './_models'

const QueryResponseContext = createResponseContext<Group>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const query = '';

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.GROUPS}`,
    () => {
      return getGroup()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
}
