const QUERIES = {
  USERS_LIST: 'users-list',
  TASKS_LIST: 'tasks-list',
  REMAINS_LIST: 'tasks-list',
  GROUP_LIST: 'group-list',
  SERVICES_LIST: 'services-list',
  EXPENSES_LIST: 'expenses-list',
  GROUPS: 'groups',
}

export {QUERIES}
