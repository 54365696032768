import {useState, FC} from 'react'
import * as Yup from 'yup'
import "yup-phone";
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import { generateCorrelationId } from '../../../../components/_helpers';
import { passwordChangeSubmit } from '../../../../components/user/password/_requests'

type Props = {
  updateStep: any
  correlationId: any
  passwordChangeId: any
}

const initialValues = {
  password: '',
  repeatPassword: '',
}

const inputSchema = Yup.object().shape({
  password: Yup.string().required('Пароль обязателен'),
  repeatPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Пароли должны совпадать').required('Повтор пароля обязателен')
})
export const StepPassword: FC<Props> = ({updateStep, correlationId, passwordChangeId}) => {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [error, setError] = useState('')
  const formik = useFormik({
    initialValues,
    validationSchema: inputSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      passwordChangeSubmit(values.password, passwordChangeId, correlationId, generateCorrelationId())
        .then(({data: {passwordChangeSubmit}}) => {
          setHasErrors(false)
          setLoading(false)
          updateStep(4)
        })
        .catch(({message}) => {
          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
          setStatus(message)
          setError(message)
        })
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>Забыли пароль ?</h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          Введите новый пароль
        </div>
      </div>

      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            {error}
          </div>
        </div>
      )}

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Новый пароль</label>
        <input
            {...formik.getFieldProps('password')}
            placeholder='Пароль'
            type='text'
            autoComplete='off'
            className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.password && formik.errors.password},
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
            )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Повтор пароля</label>
        <input
            {...formik.getFieldProps('repeatPassword')}
            placeholder='Повтор пароля'
            type='text'
            autoComplete='off'
            className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.repeatPassword && formik.errors.repeatPassword},
                {
                  'is-valid': formik.touched.repeatPassword && !formik.errors.repeatPassword,
                }
            )}
        />
        {formik.touched.repeatPassword && formik.errors.repeatPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.repeatPassword}</span>
            </div>
          </div>
        )}
      </div>

      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>Отправить</span>
          {loading && (
            <span className='indicator-progress'>
              Пожалуйста подождите...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Отмена
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
