import axios from 'axios'

export type strObj = {[key:string]:string}
class Configurator {
    constructor() {
        this.config = {}
        this.loadConfig()
    }
    config:strObj = {}
    async loadConfig () {
        let self = this
        let domain = this.getDomain()
        return axios.get('/configs/'+domain+'/config.json')
            .then(response => {
                self.config = response.data
            })
            .catch(() => {
                return axios.get('/configs/default/config.json')
                    .then(response => {
                        self.config = response.data
                    })
            })
    }
    getDomain () {
        return window.location.hostname
    }
    async getConfig (name: string) {
        if (Object.keys(this.config).length <= 0) {
            await this.loadConfig()
        }
        if (Object.hasOwn(this.config, name)) {
            return this.config[name];
        }
        return ''
    }
    async getConfigs () {
        if (Object.keys(this.config).length <= 0) {
            await this.loadConfig()
        }

        return this.config;
    }
    async getHelp () {
        let domain = this.getDomain()
        return axios.get('/configs/'+domain+'/help.html')
            .then(response => {
                return response.data
            })
            .catch(() => {
                return axios.get('/configs/default/help.html')
                    .then(response => {
                        return response.data
                    })
            })
    }
    async getFooter () {
        let domain = this.getDomain()
        return axios.get('/configs/'+domain+'/footer.html')
            .then(response => {
                return response.data
            })
            .catch(() => {
                return axios.get('/configs/default/footer.html')
                    .then(response => {
                        return response.data
                    })
            })
    }
}

let configurator = new Configurator()

export default configurator
