import ApiClient from "./ApiClient";
import { GET_REQUEST_TYPES, GET_REQUEST_SUBJECTS } from "./_queries";
import { REGISTRATION_CREATE, REGISTRATION_SUBMIT, REGISTRATION_VERIFY } from "./_mutations";
import { RequestTypesQueryResponse, RequestSubjectsQueryResponse } from "./_models"

export const getRequestTypes = (): Promise<RequestTypesQueryResponse> => {
  return ApiClient.client.query({query: GET_REQUEST_TYPES}).then((result) => {
    return {
      data: result.data.requestTypes
    }
  });
}
export const getRequestSubjects = (): Promise<RequestSubjectsQueryResponse> => {
  return ApiClient.client.query({query: GET_REQUEST_SUBJECTS}).then((result) => {
    return {
      data: result.data.requestCustomSubjects
    }
  });
}
export async function registrationCreate(
    msisdn: String,
    correlationId: String,
    actionId: String,
    registrationId: String,
    sim: String,
    email: String,
    contactName: String,
    contactPhone: String,
  ) {
  return ApiClient.client.mutate({mutation: REGISTRATION_CREATE, variables: {
    msisdn: msisdn,
    correlationId: correlationId,
    actionId: actionId,
    registrationId: registrationId,
    sim: sim,
    email: email,
    contactName: contactName,
    contactPhone: contactPhone
  }}).then((result) => {
    return {
      data: result.data
    }
  })
}
export async function registrationVerify(registrationId: String, correlationId: String, actionId: String) {
  return ApiClient.client.mutate({mutation: REGISTRATION_VERIFY, variables: {
    correlationId: correlationId,
    actionId: actionId,
    registrationId: registrationId,
  }}).then((result) => {
    return {
      data: result.data
    }
  })
}
export async function registrationSubmit(registrationId: String, correlationId: String, actionId: String) {
  return ApiClient.client.mutate({mutation: REGISTRATION_SUBMIT, variables: {
    correlationId: correlationId,
    actionId: actionId,
    registrationId: registrationId,
  }}).then((result) => {
    return {
      data: result.data
    }
  })
}
