import ApiClient from "../../../../../app/components/ApiClient";

import { ACCESS_STATUS, REGISTRATION_TYPE } from '../../../../../app/components/_queries'

export async function accessStatus(msisdn: string) {
  return await ApiClient.client.query({query: ACCESS_STATUS, variables: {msisdn: msisdn}}).then((result) => {
    return { data: result.data }
  });
}

export async function registrationType(msisdn: string) {
  return ApiClient.client.query({query: REGISTRATION_TYPE, variables: {msisdn: msisdn}}).then((result) => {
    return { data: result.data }
  });
}